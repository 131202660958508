import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../context/auth"
import { injectIntl } from "gatsby-plugin-intl"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import usePaises from "../hooks/usePaises"
import useProvincias from "../hooks/useProvincias"
import useLocalidades from "../hooks/useLocalidades"
import VolverLogin from "../components/VolverLogin"
import publicIp from "public-ip" //ACTUALIZAR A TODAS

import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core"
import { navigate, graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 8,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: 8,
  },
}))

const MisDatos = ({ intl, data }) => {
  const {
    nombre,
    bienvenida,
    link_planilla_registro,
    ingreso_habilitado,
    alert_ingreso_habilitado,
    boton_registrarme,
    boton_volver_login,
    label_nombre,
    label_apellido,
    label_email,
    label_clave,
    label_profesion,
    label_provincia,
    label_localidad,
    label_telefono,
    label_pais,
    alert_campos_obligatorios,
    label_ocupacion_dirigente_entidad,
    label_ocupacion_empresario_rubro,
    label_ocupacion_empresario_empresa,
    label_ocupacion_otro,
    label_ocupacion_dirigente,
    label_ocupacion_empresario,
    label_dni,
    label_nacimiento,
    label_ocupacion,
  } = data.strapiRegistro
  const { user } = useContext(AuthContext)
  const currentLocale = intl.locale
  const [pais, setPais] = useState("")
  const [ramo, setRamo] = useState("")
  const paises = usePaises()
  const provincias = useProvincias()
  const localidades = useLocalidades()
  paises.sort(function (a, b) {
    let left = a.nombre_pais
    let right = b.nombre_pais
    return left === right ? 0 : left > right ? 1 : -1
  })
  const pad = function (i) {
    return i < 10 ? "0" + i : i
  }
  let today = new Date()
  let date =
    today.getFullYear() +
    "-" +
    pad(today.getMonth() + 1) +
    "-" +
    pad(today.getDate())
  useEffect(() => {
    if (!user) {
      navigate("/")
    }
  }, [])
  const [crearNuevoDocumento, setCrearNuevoDocumento] = useState(false)
  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    })
  const [ip, setIp] = useState("")
  useEffect(() => {
    getClientIp().then(result => setIp(result))
  }, [])
  const [provincia, setProvincia] = useState("")
  const [localidad, setLocalidad] = useState("")
  const [ocupacion, setOcupacion] = useState("")
  const ramos_es = [
    "Industria",
    "Automotor",
    "Aviación y aeroespacial",
    "Materiales de construcción y construcción",
    "Tecnología limpia",
    "Bienes de consumo",
    "Defender y Homeland Security",
    "Educación",
    "Servicio Financiero y Profesional",
    "Alimentos / Agricultura",
    "Tecnologías de la información",
    "Logística, distribución e infraestructura",
    "Ciencias de la vida y tecnología médica",
    "Fabricación",
    "Embarcaciones y equipos marinos",
    "Otro",
  ]
  const ramos_en = [
    "Industry",
    "Automotive",
    "Aviation and aerospace",
    "Building and construction materials",
    "Clean technology",
    "Consumer goods",
    "Defender and Homeland Security",
    "Education",
    "Financial and Professional Service",
    "Food / Agriculture",
    "Information technology",
    "Logistics, distribution, and infrastructure",
    "Life Sciences and Medical Technology",
    "Manufacturing",
    "Marine vessels and equipment",
    "Other",
  ]
  const ramos_pt = [
    "Indústria",
    "Automotivo",
    "Aviação e aeroespacial",
    "Construção e materiais de construção",
    "Tecnologia limpa",
    "Bens de consumo",
    "Defender e Segurança Interna",
    "Educação",
    "Serviço Financeiro e Profissional",
    "Alimentos / Agricultura",
    "Tecnologia da Informação",
    "Logística, distribuição e infraestrutura",
    "Ciências da Vida e Tecnologia Médica",
    "Manufatura",
    "Embarcações e equipamentos marítimos",
    "Outro",
  ]
  const ramos =
    currentLocale === "en"
      ? ramos_en
      : currentLocale === "pt"
      ? ramos_pt
      : ramos_es

  const handleChangeProvincia = event => {
    setLocalidad("")
    setProvincia(event.target.value)
  }
  const handleChangeLocalidad = event => {
    setLocalidad(event.target.value)
  }
  const handleChangeOcupacion = event => {
    setOcupacion(event.target.value)
  }
  const [dataMisDatos, setDataMisDatos] = useState({
    email: "", // no EDITABLE
    password: "",
    error: null,
    nombre: "",
    apellido: "",
    telefono: "",
    dni: "",
    ocupacion_otro: "",
    ocupacion_empresario_empresa: "",
    fecha_nacimiento: date,
    camara_entidad: "",
  })

  useEffect(() => {
    firebase
      .firestore()
      .collection("usuarios")
      .doc(user.uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          setPais(doc.data().pais)
          setRamo(doc.data().ramo)
          setProvincia(doc.data().provincia)
          setLocalidad(doc.data().localidad)
          setDataMisDatos({
            email: user.email,
            nombre: doc.data().nombre,
            apellido: doc.data().apellido,
            telefono: doc.data().telefono,
            ocupacion: doc.data().ocupacion,
            dni: doc.data().dni,
            camara_entidad: doc.data().camara_entidad,
            ocupacion_otro: doc.data().ocupacion_otro,
            ocupacion_empresario_empresa:
              doc.data().ocupacion_empresario_empresa,
            fecha_nacimiento: doc.data().fecha_nacimiento,
          })
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
          setCrearNuevoDocumento(true)
          alert(
            currentLocale === "en"
              ? "Please fill all the fields"
              : currentLocale === "pt"
              ? "Please fill all the fields"
              : "Por favor completar todos los campos"
          )
        }
      })
      .catch(error => {
        console.log("Error getting document:", error)
      })
  }, [])

  const handleChangePais = event => {
    setPais(event.target.value)
  }

  const handleChangeRamo = event => {
    setRamo(event.target.value)
  }

  const handleChange = e => {
    setDataMisDatos({ ...dataMisDatos, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setDataMisDatos({ ...dataMisDatos, error: null })
    if (
      dataMisDatos.nombre !== "" &&
      dataMisDatos.apellido !== "" &&
      dataMisDatos.email !== "" &&
      dataMisDatos.password !== "" &&
      dataMisDatos.telefono !== "" &&
      pais !== ""
    ) {
      grabarUsuario()
    } else {
      alert(
        currentLocale === "en"
          ? "All fields are required"
          : currentLocale === "pt"
          ? "All fields are required"
          : "Todos los campos son obligatorios"
      )
    }
  }

  const grabarUsuario = props => {
    if (crearNuevoDocumento) {
      firebase
        .firestore()
        .collection("usuarios")
        .doc(user.uid)
        .set({
          nombre: dataMisDatos.nombre.trim(),
          apellido: dataMisDatos.apellido.trim(),
          pais: pais,
          codpais: CodigoPais(pais),
          telefono: dataMisDatos.telefono.trim(),
          ocupacion: ocupacion,
          dni: dataMisDatos.dni,
          camara_entidad: dataMisDatos.camara_entidad,
          ocupacion_otro: dataMisDatos.ocupacion_otro,
          ocupacion_empresario_empresa:
            dataMisDatos.ocupacion_empresario_empresa,
          fecha_nacimiento: dataMisDatos.fecha_nacimiento,
          localidad: localidad,
          provincia: provincia,
          rubro: ramo,
          email: user.email,
          uid: user.uid,
          ip: ip
        })
        .then(res => {
          user
            .updateProfile({
              displayName: `${dataMisDatos.nombre.trim()} ${dataMisDatos.apellido.trim()}`,
            })
            .then(function () {
              alert(intl.formatMessage({ id: "cambiosguardados" }))
            })
            .catch(function (error) {
              // An error happened.
            })
        })
    } else {
      firebase
        .firestore()
        .collection("usuarios")
        .doc(user.uid)
        .update({
          nombre: dataMisDatos.nombre.trim(),
          apellido: dataMisDatos.apellido.trim(),
          pais: pais,
          codpais: CodigoPais(pais),
          telefono: dataMisDatos.telefono.trim(),
          ocupacion: ocupacion,
          dni: dataMisDatos.dni,
          camara_entidad: dataMisDatos.camara_entidad,
          ocupacion_otro: dataMisDatos.ocupacion_otro,
          ocupacion_empresario_empresa:
            dataMisDatos.ocupacion_empresario_empresa,
          fecha_nacimiento: dataMisDatos.fecha_nacimiento,
          localidad: localidad,
          provincia: provincia,
          rubro: ramo,
        })
        .then(res => {
          user
            .updateProfile({
              displayName: `${dataMisDatos.nombre.trim()} ${dataMisDatos.apellido.trim()}`,
            })
            .then(function () {
              alert(intl.formatMessage({ id: "cambiosguardados" }))
            })
            .catch(function (error) {
              // An error happened.
            })
        })
    }
  }
  const classes = useStyles()
  const CodigoPais = pais => {
    let alfa2
    paises.filter(function (paisFiltrado) {
      if (
        paisFiltrado.nombre.es.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.en.toLowerCase().includes(pais.toLowerCase()) ||
        paisFiltrado.nombre.pt.toLowerCase().includes(pais.toLowerCase())
      ) {
        if (
          paisFiltrado.nombre.es.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.en.toLowerCase() === pais.toLowerCase() ||
          paisFiltrado.nombre.pt.toLowerCase() === pais.toLowerCase()
        ) {
          alfa2 = paisFiltrado.alfa2
        }
      }
    })
    return alfa2
  }
  /****************
   * codigo pais fin
   */

  return (
    <>
      {user != null ? (
        <Layout>
          <SEO title={intl.formatMessage({ id: "misdatos" })} />
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "misdatos" })}
              </Typography>

              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="nombre"
                  label={
                    currentLocale === "en"
                      ? label_nombre.en
                      : currentLocale === "pt"
                      ? label_nombre.pt
                      : label_nombre.es
                  }
                  type="text"
                  id="nombre"
                  value={dataMisDatos.nombre}
                  onChange={handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="apellido"
                  label={
                    currentLocale === "en"
                      ? label_apellido.en
                      : currentLocale === "pt"
                      ? label_apellido.pt
                      : label_apellido.es
                  }
                  type="text"
                  id="apellido"
                  value={dataMisDatos.apellido}
                  onChange={handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  disabled
                  id="email"
                  label={
                    currentLocale === "en"
                      ? label_email.en
                      : currentLocale === "pt"
                      ? label_email.pt
                      : label_email.es
                  }
                  name="email"
                  // autoComplete="email"
                  value={user.email}
                  // onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  name="dni"
                  label={
                    currentLocale === "en"
                      ? label_dni.en
                      : currentLocale === "pt"
                      ? label_dni.pt
                      : label_dni.es
                  }
                  type="text"
                  id="DNI"
                  value={dataMisDatos.dni}
                  onChange={handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="fecha_nacimiento"
                  label={
                    currentLocale === "en"
                      ? label_nacimiento.en
                      : currentLocale === "pt"
                      ? label_nacimiento.pt
                      : label_nacimiento.es
                  }
                  type="date"
                  id="fecha_nacimiento"
                  value={dataMisDatos.fecha_nacimiento}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  name="telefono"
                  label={
                    currentLocale === "en"
                      ? label_telefono.en
                      : currentLocale === "pt"
                      ? label_telefono.pt
                      : label_telefono.es
                  }
                  type="tel"
                  id="telefono"
                  value={dataMisDatos.telefono}
                  onChange={handleChange}
                />

                <InputLabel id="ocupacionLabel" style={{ padding: 15 }}>
                  {currentLocale === "en"
                    ? label_ocupacion.en
                    : currentLocale === "pt"
                    ? label_ocupacion.pt
                    : label_ocupacion.es}
                </InputLabel>
                <Select
                  style={{ width: "100%", padding: 10 }}
                  id="ocupacion"
                  value={ocupacion}
                  onChange={handleChangeOcupacion}
                >
                  <MenuItem value={""}></MenuItem>
                  <MenuItem value={"empresario"}>
                    {currentLocale === "en"
                      ? label_ocupacion_empresario.en
                      : currentLocale === "pt"
                      ? label_ocupacion_empresario.pt
                      : label_ocupacion_empresario.es}
                  </MenuItem>
                  <MenuItem value={"dirigente"}>
                    {currentLocale === "en"
                      ? label_ocupacion_dirigente.en
                      : currentLocale === "pt"
                      ? label_ocupacion_dirigente.pt
                      : label_ocupacion_dirigente.es}
                  </MenuItem>
                  <MenuItem value={"otro"}>
                    {currentLocale === "en"
                      ? label_ocupacion_otro.en
                      : currentLocale === "pt"
                      ? label_ocupacion_otro.pt
                      : label_ocupacion_otro.es}
                  </MenuItem>
                </Select>

                {ocupacion === "otro" ? (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="ocupacion_otro"
                    label={
                      currentLocale === "en"
                        ? label_ocupacion_otro.en
                        : currentLocale === "pt"
                        ? label_ocupacion_otro.pt
                        : label_ocupacion_otro.es
                    }
                    type="text"
                    id="ocupacion_otro"
                    value={dataMisDatos.ocupacion_otro}
                    onChange={handleChange}
                  />
                ) : ocupacion === "empresario" ? (
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="ocupacion_empresario_empresa"
                      label={
                        currentLocale === "en"
                          ? label_ocupacion_empresario_empresa.en
                          : currentLocale === "pt"
                          ? label_ocupacion_empresario_empresa.pt
                          : label_ocupacion_empresario_empresa.es
                      }
                      type="text"
                      id="ocupacion_empresario_empresa"
                      value={dataMisDatos.ocupacion_empresario_empresa}
                      onChange={handleChange}
                    />
                    <InputLabel id="ramosLabel" style={{ padding: 5 }}>
                      {currentLocale === "en"
                        ? label_ocupacion_empresario_rubro.en
                        : currentLocale === "pt"
                        ? label_ocupacion_empresario_rubro.pt
                        : label_ocupacion_empresario_rubro.es}
                    </InputLabel>
                    <Select
                      style={{ width: "100%", padding: 5 }}
                      id="categoria"
                      value={ramo}
                      onChange={handleChangeRamo}
                    >
                      {ramos.map(ramo => (
                        <MenuItem value={ramo}>{ramo}</MenuItem>
                      ))}
                    </Select>
                  </>
                ) : ocupacion === "dirigente" ? (
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="camara_entidad"
                      label={
                        currentLocale === "en"
                          ? label_ocupacion_dirigente_entidad.en
                          : currentLocale === "pt"
                          ? label_ocupacion_dirigente_entidad.pt
                          : label_ocupacion_dirigente_entidad.es
                      }
                      type="text"
                      id="camara_entidad"
                      value={dataMisDatos.camara_entidad}
                      onChange={handleChange}
                    />
                  </>
                ) : (
                  ""
                )}

                <InputLabel id="paisLabel" style={{ padding: 15 }}>
                  {currentLocale === "en"
                    ? label_pais.en
                    : currentLocale === "pt"
                    ? label_pais.pt
                    : label_pais.es}
                </InputLabel>
                <Select
                  style={{ width: "100%", padding: 10 }}
                  id="pais"
                  value={pais}
                  onChange={handleChangePais}
                >
                  {paises.map(pais =>
                    pais.mostrar ? (
                      <MenuItem value={pais.nombre.es}>
                        <>
                          {currentLocale === "en"
                            ? pais.nombre.en
                            : currentLocale === "pt"
                            ? pais.nombre.pt
                            : pais.nombre.es}
                        </>
                      </MenuItem>
                    ) : (
                      ""
                    )
                  )}
                </Select>
                {pais === "Argentina" ? (
                  <>
                    <InputLabel id="provinciaLabel" style={{ padding: 15 }}>
                      {currentLocale === "en"
                        ? label_provincia.en
                        : currentLocale === "pt"
                        ? label_provincia.pt
                        : label_provincia.es}
                    </InputLabel>
                    <Select
                      style={{ width: "100%", padding: 10 }}
                      id="provincia"
                      value={provincia}
                      onChange={handleChangeProvincia}
                    >
                      {provincias.map(provincia =>
                        provincia.mostrar && provincia.pais === pais ? (
                          <MenuItem value={provincia.nombre}>
                            <>
                              {currentLocale === "en"
                                ? provincia.nombre
                                : currentLocale === "pt"
                                ? provincia.nombre
                                : provincia.nombre}
                            </>
                          </MenuItem>
                        ) : (
                          ""
                        )
                      )}
                    </Select>
                  </>
                ) : pais !== "" ? (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="provincia"
                    label={
                      currentLocale === "en"
                        ? label_provincia.en
                        : currentLocale === "pt"
                        ? label_provincia.pt
                        : label_provincia.es
                    }
                    type="text"
                    id="provincia"
                    value={provincia}
                    onChange={handleChangeProvincia}
                  />
                ) : (
                  ""
                )}
                {pais === "Argentina" && provincia !== "" ? (
                  <>
                    <InputLabel id="localidadLabel" style={{ padding: 15 }}>
                      {currentLocale === "en"
                        ? label_localidad.en
                        : currentLocale === "pt"
                        ? label_localidad.pt
                        : label_localidad.es}
                    </InputLabel>
                    <Select
                      style={{ width: "100%", padding: 10 }}
                      //labelId="pais"
                      id="localidad"
                      value={localidad}
                      onChange={handleChangeLocalidad}
                    >
                      {localidades.map(localidad =>
                        localidad.mostrar &&
                        localidad.provincia === provincia ? (
                          <MenuItem value={localidad.nombre}>
                            <>
                              {currentLocale === "en"
                                ? localidad.nombre
                                : currentLocale === "pt"
                                ? localidad.nombre
                                : localidad.nombre}
                            </>
                          </MenuItem>
                        ) : (
                          ""
                        )
                      )}
                    </Select>{" "}
                  </>
                ) : pais !== "" && provincia !== "" ? (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="localidad"
                    label={
                      currentLocale === "en"
                        ? label_localidad.en
                        : currentLocale === "pt"
                        ? label_localidad.pt
                        : label_localidad.es
                    }
                    type="text"
                    id="localidad"
                    value={localidad}
                    onChange={handleChangeLocalidad}
                  />
                ) : (
                  ""
                )}
                {dataMisDatos.error ? (
                  <p style={{ color: "red" }}>{dataMisDatos.error}</p>
                ) : null}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {intl.formatMessage({ id: "guardar" })}
                </Button>
              </form>
            </div>
          </Container>
        </Layout>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default injectIntl(MisDatos)

export const query = graphql`
  query {
    strapiRegistro {
      alert_campos_obligatorios {
        es
        en
        pt
      }
      label_dni {
        es
        en
        pt
      }
      label_nacimiento {
        es
        en
        pt
      }
      label_ocupacion {
        es
        en
        pt
      }
      label_ocupacion_empresario {
        es
        en
        pt
      }
      label_ocupacion_dirigente {
        es
        en
        pt
      }
      label_ocupacion_otro {
        es
        en
        pt
      }
      label_ocupacion_empresario_empresa {
        es
        en
        pt
      }
      label_ocupacion_empresario_rubro {
        es
        en
        pt
      }
      label_ocupacion_dirigente_entidad {
        es
        en
        pt
      }
      label_pais {
        es
        en
        pt
      }
      label_telefono {
        es
        en
        pt
      }
      label_profesion {
        es
        en
        pt
      }
      label_clave {
        es
        en
        pt
      }
      label_email {
        es
        en
        pt
      }
      label_apellido {
        es
        en
        pt
      }
      label_nombre {
        es
        en
        pt
      }
      label_provincia {
        es
        en
        pt
      }
      label_localidad {
        es
        en
        pt
      }
      nombre {
        es
        en
        pt
      }
      boton_volver_login {
        es
        en
        pt
        color_fondo
        color_letra
      }
      boton_registrarme {
        es
        en
        pt
        color_fondo
        color_letra
      }
      ingreso_habilitado
      alert_ingreso_habilitado {
        es
        en
        pt
      }
      bienvenida {
        es
        en
        pt
      }
    }
  }
`
